<template lang="pug">
.vehicle-desc-block
  div(v-if="!hasVehicleIncludes")
    .description-title {{ includes.headline }}
    div.check-list
      div.check-list-item(v-for="item in includesList")
        i.fas.fa-check.check-list-circle
        span.check-list-text {{ item.text }}

  .d-none.d-md-block(v-if="hasVehicleIncludes")
    .check-list-title.m-t-40 {{ vehicleIncludes.headline }}
    div.check-list
      div.check-list-item(v-for="item in vehicleIncludes.items.data")
        i.fas.fa-check.check-list-circle(:style="{'background': vehicleIncludes.circle_color, 'color': vehicleIncludes.circle_text_color}")
        span.check-list-text {{ item.text }}

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VehicleDescription',
  computed: {
    ...mapState('reseller', {
      includes: (state) =>
        state.customStyle.includes && !!state.customStyle.includes.data.length
          ? state.customStyle.includes.data[0]
          : {},
    }),
    ...mapState('product', ['vehicle']),
    hasResellerIncludes() {
      return !!this.includes.items && !!this.includes.items.data.length
    },
    hasVehicleIncludes() {
      return (
        !!this.vehicle.builderIncludes &&
        !!this.vehicle.builderIncludes.data.length &&
        !!this.vehicle.builderIncludes.data[0].items.data.length
      )
    },
    includesList() {
      return this.includes.items ? this.includes.items.data : ''
    },
    vehicleIncludes() {
      return this.vehicle.builderIncludes.data[0]
    },
  },
}
</script>
